


export class BrowserAppEvaluationToolInfo {

  public version = '2024.5.23.2';

  constructor() {
  }

}
